import React from 'react';

import Page from 'containers/page';
import Banner from 'containers/landing-page/banner';
import Testimonial from 'containers/landing-page/testimonial';
import Calculator from 'containers/landing-page/calculator';
import Features from 'containers/landing-page/features';
import Contact from 'containers/landing-page/contact';
import getData from '../data/restaurant-webshop';
import {loadLangFromLocalStorage} from '../components/language-modal';

export default () => {
  const lang = loadLangFromLocalStorage() || 'de';
  const data = getData(lang);
  return (
    <Page overrideMenuItems={data.navbarMenuItems} seoProps={{
      title: data.title,
    }} hideMobileMenu>
      <Banner bannerText={data.banner} dir={data.direction}/>
      <Testimonial reviews={data.reviews} dir={data.direction}/>
      <Calculator translations={data.calculator} dir={data.direction}/>
      <Features features={data.features} dir={data.direction}/>
      <Contact data={data.contact} dir={data.direction}/>
      <Features features={data.nextFeatures} dir={data.direction}/>
      {/*hidden forms for netlify*/}
      <form name="contact-form" style={{display: 'none'}} data-netlify={true}>
        <input  name="form-name" value="contact-form" style={{visibility: 'none'}}/>
        <input  name="name" value="" style={{visibility: 'none'}}/>
        <input  name="restaurant" value="" style={{visibility: 'none'}}/>
        <input  name="mobile" value="" style={{visibility: 'none'}}/>
        <input  name="email" value="" style={{visibility: 'none'}}/>
        <input  name="zipCode" value="" style={{visibility: 'none'}}/>
        <input  name="plan" value="" style={{visibility: 'none'}}/>
        <input  name="fee" value="" style={{visibility: 'none'}}/>
        <input  name="agb" value="" style={{visibility: 'none'}}/>
      </form>
    </Page>
  );
};