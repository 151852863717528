import React from 'react';
import { Icon } from 'react-icons-kit';
import { arrowRight } from 'react-icons-kit/feather/arrowRight';
import { check } from 'react-icons-kit/feather/check';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Link from 'common/components/Link';
import Text from 'common/components/Text';
import GatsbyImage from 'common/components/GatsbyImage';

import {
  Section,
  SectionHeading,
  ContentWrapper,
  Illustration,
  TextContent,
  ButtonGroup,
  Features,
} from './contact.style';
import ContactModal from "../../../components/contact-modal";

const Contact = ({data, dir}) => {
  return (
    <Section id="contact" dir={dir}>
      <Container noGutter>
        <SectionHeading>
          <Heading content={data.callToAction} />
        </SectionHeading>
        <ContentWrapper>
          <TextContent>
            <Heading
              className="title"
              content={data.title}
            />
            <Text
              className="desc"
              content={data.description}
            />
            <ButtonGroup>
              <ContactModal label={data.action.label}/>
            </ButtonGroup>
            <Features>
              {
                data.features.map((f) =>  <li>
                  <Icon size={20} icon={check} /> {f}
                </li>)
              }
            </Features>
          </TextContent>
          <Illustration>
            <GatsbyImage src={data.image.src} alt={data.image.alt} />
          </Illustration>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

export default Contact;
