import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Heading from 'common/components/Heading';
import {Button} from 'baseui/button';
import Text from 'common/components/Text';
import GatsbyImage from 'common/components/GatsbyImage';
import {
  Section,
  ContentWrapper,
  TextContent,
  Illustration,
  MobileIllustration,
  ButtonGroup,
} from './banner.style';
import {Link} from 'gatsby';
import ContactModal from "../../../components/contact-modal";

const Banner = ({bannerText, dir}) => {
  const data = useStaticQuery(graphql`
    query {
      illustration: file(
        relativePath: { eq: "image/landing-page/hero.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <Section id="home" dir={dir}>
      <ContentWrapper>
        <TextContent>
          <span>{bannerText.subTitle}</span>
          <Heading content={bannerText.title}/>
          <MobileIllustration>
            <iframe width="100%" height="auto"
                    src="https://www.youtube.com/embed/VHUtg3Gw4ss"
                    title="Easyresto Store"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
          </MobileIllustration>
          <Text
            content={bannerText.description}/>
          <ButtonGroup>
            <ContactModal label={bannerText.actionLabel}/>
          </ButtonGroup>
        </TextContent>
      </ContentWrapper>
      <Illustration>
        <iframe width="100%" height="auto"
                src="https://www.youtube.com/embed/VHUtg3Gw4ss"
                title="Easyresto Store"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
      </Illustration>
    </Section>
  );
};

export default Banner;
