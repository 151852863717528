import styled from 'styled-components';

export const Section = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${props => props.dir ? `row-reverse` : `row`};
  width: 100%;
  padding: 0 116px;
  background: #ecf311;
  @media only screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 72px 0;
  @media only screen and (max-width: 1440px) {
    margin: 72px 0;
  }
  @media only screen and (max-width: 1366px) {
    margin: 72px 0;
  }
  @media only screen and (max-width: 1024px) {
   margin: 64px 0;
  }
  @media only screen and (max-width: 480px) {
    margin: 48px 0;
  }
  @media only screen and (max-width: 360px) {
    margin: 36px 0;
  }
`;

export const TextContent = styled.div`
  max-width: 550px;
  @media only screen and (max-width: 1200px) {
    max-width: 377px;
  }
  @media only screen and (max-width: 768px) {
    margin: 20px auto 0;
    text-align: center;
  }
  h2 {
    color: #0e212f;
    font-weight: 700;
    font-size: 62px;
    line-height: 1.13;
    letter-spacing: -2px;
    margin-bottom: 20px;
    @media only screen and (max-width: 1440px) {
      font-size: 50px;
    }
    @media only screen and (max-width: 1366px) {
      font-size: 45px;
      line-height: 1.3;
    }
    @media only screen and (max-width: 1200px) {
      font-size: 34px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 28px;
    }
  }
  p {
    color: #0e212f;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.9;
    letter-spacing: -0.3px;
    max-width: 514px;
    margin-bottom: 45px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
  * > {
    :first-child {
      margin-bottom: 8px;
    }
  }
`;

export const Illustration = styled.figure`
  position: relative;
  max-height: 100%;
  max-width: 50%;
  overflow: hidden;
  
  iframe {
    min-width: 600px;
    min-height: 350px;
    border: 1px solid #eee;
  }
  
  @media only screen and (max-width: 768px) {
    display: none;
  }
  
  
  .gatsby-image-wrapper {
    height: 100%;
  }
  img {
    height: 100%;
  }
  @media only screen and (max-width: 768px) {
    max-width: 100%;
    img {
      width: 100%;
    }
  }
`;

export const MobileIllustration = styled(Illustration)`
  @media only screen and (min-width: 768px) {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    display: inline-flex;
    width: 100%;
    align-items: center;
    right: 0;
    top: 0;
    position: relative;
    height: auto;
    transform: none;
  }
  img {
    margin-top: 0;
    height: auto;
    max-width: 100%;
  }
  iframe {
    min-width: 100%;
    min-height: 200px;
  }
`;
