import React from 'react';
import {InnerContainer, Section, QuestionContainer, QuestionChoices, AnswerContainer} from './calculator.style';
import {Select} from "baseui/select";


const provisionsOptions = (label) => ([7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map((v) => ({
  id: v,
  label: `${label} ${v}%`
})));

const ordersPerMonthsOptions = (label) => ([50, 100, 200, 300, 400, 500, 700, 1000, 1500].map((v) => ({
  id: v,
  label: `${v} ${label}`
})));
const orderValueOptions = (label) => ([25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100].map((v) => ({
  id: v,
  label: `${label} ${v}€`
})));

const Calculator = ({translations, dir}) => {
  const [result, setResult] = React.useState({
    provision: [provisionsOptions(translations.provision).find((i) => i.id === 10)],
    ordersPerMonth: [ordersPerMonthsOptions(translations.ordersPerMonth).find((i) => i.id === 300)],
    orderValue: [orderValueOptions(translations.orderValue).find((i) => i.id === 25)],
  });

  const calculate = () => {
    const savings = ((result.provision[0].id / 100) * (result.ordersPerMonth[0].id) * result.orderValue[0].id) - 50;
    const formatter = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    });
    return formatter.format(savings);
  }

  return <Section dir={dir}>
    <InnerContainer>
      <QuestionContainer>
        <h2>{translations.title}</h2>
        <QuestionChoices>
          <Select options={provisionsOptions(translations.provision)}
                  labelKey="label"
                  valueKey="id"
                  searchable={false}
                  clearable={false}
                  placeholder={''}
                  onChange={({value}) => setResult({...result, provision: value})}
                  value={result.provision}
                  overrides={{
                    OptionContent: {
                      style: {
                        direction: dir ? `rtl` : `ltr`,
                        textAlign: dir ? 'right' : 'left',
                      }
                    }
                  }}
          />
          <Select options={ordersPerMonthsOptions(translations.ordersPerMonth)}
                  labelKey="label"
                  valueKey="id"
                  searchable={false}
                  clearable={false}
                  placeholder={''}
                  onChange={({value}) => setResult({...result, ordersPerMonth: value})}
                  value={result.ordersPerMonth}
                  overrides={{
                    OptionContent: {
                      style: {
                        direction: dir ? `rtl` : `ltr`,
                        textAlign: dir ? 'right' : 'left',
                      }
                    }
                  }}
          />
          <Select options={orderValueOptions(translations.orderValue)}
                  labelKey="label"
                  valueKey="id"
                  searchable={false}
                  clearable={false}
                  placeholder={''}
                  onChange={({value}) => setResult({...result, orderValue: value})}
                  value={result.orderValue}
                  overrides={{
                    OptionContent: {
                      style: {
                        direction: dir ? `rtl` : `ltr`,
                        textAlign: dir ? 'right' : 'left',
                      }
                    }
                  }}
          />
        </QuestionChoices>
      </QuestionContainer>
      <AnswerContainer>
        <h4 style={{fontSize: '20px', padding: 0, margin: 0,}}>{translations.result}</h4>
        <h3 style={{display: 'flex', flexDirection: 'column', padding: '8px 0 0 0', margin: 0,}}>
          <span style={{fontSize: '32px', color: '#6600f0'}}>{calculate()}</span>
          <span style={{color: '#aaa', fontWeight: 'normal'}}>{translations.perMonth}</span>
        </h3>
      </AnswerContainer>
    </InnerContainer>
  </Section>
}

export default Calculator;