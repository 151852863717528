import React from 'react';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import GatsbyImage from 'common/components/GatsbyImage';

import {
  Section,
  ContentWrapper,
  Illustration,
  TextContent,
} from './features.style';

const Features = ({features, dir}) => {
  return (
    <Section dir={dir}>
      <Container>
        {
          features.map((f, index) => <ContentWrapper reverse={index % 2 === 0} >
            <Illustration>
              <GatsbyImage
                src={f.image.src}
                alt={f.image.alt}
              />
            </Illustration>
            <TextContent>
              <Text
                as="span"
                className="slogan"
                content={f.slogan}
              />
              <Heading
                className="title"
                content={f.title}
              />
              <Text
                className="desc"
                content={f.description}
              />
            </TextContent>
          </ContentWrapper>)
        }
      </Container>
    </Section>
  );
};

export default Features;
