import styled from 'styled-components';


export const Section = styled.div`
  width: 100%;
  background: #ecf311;
  padding: 32px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: ${props => props.dir ? `right` : `left`};
  direction: ${props => props.dir ? `rtl` : `ltr`};
  flex-direction: ${props => props.dir ? `row-reverse` : `row`};
  @media only screen and (max-width: 768px) {
    padding: 16px;
  }
`;

export const InnerContainer = styled.div`
   max-width: 1040px;
   width: 100%;
   background: #fff;
   border-radius: 10px;
   display: flex;
   @media only screen and (max-width: 768px) {
    flex-direction: column; 
  }
`;

export const QuestionContainer = styled.div`
  padding: 32px;
`;

export const QuestionChoices = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  .w-200 {
    width: 200px;
  }
  .w-290 {
    width: 290px;
  }
  .w-180 {
    width: 180px;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column; 
  }
`;

export const AnswerContainer = styled.div`
  min-width: 250px;
  flex: 1;
  padding: 24px;
  background: #ecf3115c;
  display: flex;
  flex-direction: column;
`